// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const articles = [
	{
		"issue": "web & mobile Developer",
		"year": 2018,
		"month": 6,
		"title": "Refactoring in JavaScript",
		"url": "https://www.webundmobile.de/update/inhalt/highlights-ausgabe-6-2018-1538946.html",
		"image": "web-mobile-developer_2018_06.jpg",
		"excerpt": "",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2018,
		"month": 5,
		"title": "Microservices in Node.js",
		"url": "https://www.webundmobile.de/update/inhalt/highlights-ausgabe-5-2018-1531880.html",
		"image": "web-mobile-developer_2018_05.jpg",
		"excerpt": "",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2018,
		"month": 4,
		"title": "Clean Code Konzepte in JavaScript",
		"url": "https://www.webundmobile.de/update/inhalt/highlights-ausgabe-4-2018-1485178.html",
		"image": "web-mobile-developer_2018_04.jpg",
		"excerpt": "",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2018,
		"month": 4,
		"title": "Asynchrone Fluent API in JavaScript",
		"url": "https://www.webundmobile.de/update/inhalt/highlights-ausgabe-4-2018-1485178.html",
		"image": "web-mobile-developer_2018_04.jpg",
		"excerpt": "",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "heise Developer",
		"year": 2018,
		"month": 3,
		"title": "JavaScript: Die Neuerungen in ES2018",
		"url": "https://www.heise.de/-3978938",
		"image": "heise-developer-logo.png",
		"excerpt": "Der aktuelle Sprachstandard für JavaScript steht fest. Das ist eine gute Gelegenheit für einen Überblick über die Neuerungen.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2018,
		"month": 3,
		"title": "Best Practices und Design Patterns für Node.js-Anwendungen",
		"url": "https://www.webundmobile.de/update/inhalt/highlights-ausgabe-3-2018-1473773.html",
		"image": "web-mobile-developer_2018_03.jpg",
		"excerpt": "Bei der Entwicklung von Node.js-Anwendungen gibt es viele Best Practices. Dieser Artikel zeigt eine Auswahl davon unter anderem bezüglich der Struktur von Packages, dem Build-Prozess, der Konfiguration von Anwendungen, dem Verwalten von Abhängigkeiten und dem Testen.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 11,
		"title": "Entwurfsmuster in JavaScript: Mixins",
		"url": "https://www.webundmobile.de/update/inhalt/highlights-ausgabe-11-2017-1421943.html",
		"image": "web-mobile-developer_2017_11.jpg",
		"excerpt": "Mixins bezeichnen in der objektorientierten Programmierung zusammengehörige Funktionalität, die bestehenden Klassen hinzugefügt werden kann. Dieser Artikel zeigt, wie sich Mixins in JavaScript realisieren lassen.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 10,
		"title": "Web Sensor APIs",
		"url": "https://www.webundmobile.de/update/phone/highlights-ausgabe-10-2017-1385632.html",
		"image": "web-mobile-developer_2017_10.jpg",
		"excerpt": "Im Zeitalter des Internet of Things ist der Zugriff auf Sensoren ein relevanter Punkt, der zunehmend auch für Webanwendungen interessant wird. Beispielsweise, um in einer mHealth-Anwendung auf Beschleunigungsinformationen des Patienten-Smartphones zuzugreifen und dadurch die Bewegungen des Patienten zu erkennen, etwa zur Erkennung von Stürzen. Zwar können einige Sensordaten wie die genannten Beschleunigungsinformationen bereits heute über APIs abgerufen werden, die nativ von Android oder iOS oder über Plugins durch Apache Cordova oder Adobe PhoneGap  bereitgestellt werden. Eine Standardisierung dieser APIs fehlt allerdings bislang. Dieser Artikel gibt einen kurzen Überblick über die Web Sensor APIs, die momentan beim W3C entstehen.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "heise Developer",
		"year": 2017,
		"month": 9,
		"title": "REST-APIs mit Node.js und Swagger",
		"url": "https://www.heise.de/-3820025",
		"image": "heise-developer-logo.png",
		"excerpt": "Wer sich mit der Konzeption und Entwicklung von REST-APIs beschäftigt, landet früher oder später bei einer API-Beschreibungssprache wie RAML, API Blueprint oder Swagger. Gerade Letzteres lässt die Integration in Node.js-Anwendungen relativ einfach von der Hand gehen.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 9,
		"title": "Nützliche Pakete: Kommandozeile und Dateisystem unter Node.js",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-9-2017-1248190.html",
		"image": "web-mobile-developer_2017_09.jpg",
		"excerpt": "Die offizielle Registry des Node.js Package Managers (NPM) listet derzeit rund eine halbe Millionen Packages. Sicherlich zuviele für einen einzigen Artikel, weswegen in diesem Artikel auf eine kleine Auswahl an Packages aus den Kategorien \"Arbeiten mit der Kommandozeile\" und \"Arbeiten mit dem Dateisystem\" eingegangen wird.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 8,
		"title": "Comeback: SVG-Bibliotheken",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-8-2017-1239440.html",
		"image": "web-mobile-developer_2017_08.jpg",
		"excerpt": "Das Format SVG (Scalable Vector Graphics) hat in den letzten Jahren ein gehöriges Comeback gefeiert. Dennoch ist es relativ mühsam, SVG \"von Hand\" selbst zu erstellen. Grund genug, sich einen kurzen Überblick über vorhandene Bibliotheken zu verschaffen, die einem als Entwickler ermöglichen, SVG per JavaScript zu erstellen und – in einigen Fällen – auch zu animieren.",
		"tags": ["JS", "HTML"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 7,
		"title": "MQTT unter Node.js",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-7-2017-1229582.html",
		"image": "web-mobile-developer_2017_07.jpg",
		"excerpt": "Bei MQTT (dem \"Message Queue Telemetry Transport\") handelt es sich um ein Messaging-Protokoll, welches vor allem im Bereich des Internet of Things zum Einsatz kommt, beispielsweise in der Industrie 4.0, im Zusammenspiel mit eHealth- und mHealth-Anwendungen und in Smart-Home- oder sogar Smart-City-Szenarien. Dieser Artikel zeigt, wie sich das Protokoll unter Node.js verwenden lässt.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 7,
		"title": "Web Bluetooth API",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-7-2017-1229582.html",
		"image": "web-mobile-developer_2017_07.jpg",
		"excerpt": "Bluetooth 4.0 und das damit einhergehende BLE (Abkürzung für Bluetooth Low Energy), welches wie der Name schon andeutet möglichst batterieschonend arbeitet ist als Protokoll im Bereich Internet of Things nicht mehr wegzudenken. Man denke nur an all die mobilen Fitness- oder mHealth-Anwendungen, die sich über genau dieses Protokoll mit den jeweiligen Sensorgeräten (beispielsweise Schrittzähler, Körpergewichtswaagen, Blutdruckmessgeräten oder ähnlichem) verbinden, die entsprechenden Sensordaten auslesen und zur Weiterverarbeitung oder Analyse in die Cloud weiterleiten.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "Creative Bloq",
		"year": 2017,
		"month": 6,
		"title": "15 Web APIs you've never heard of",
		"url": "http://www.creativebloq.com/features/15-web-apis-youve-never-heard-of",
		"excerpt": "There is really a huge number of Web APIs available and sometimes as a web developer it can be hard to keep track of new APIs. In this article I've collected a list of Web APIs that are not finalized yet and in very early stages, but are likely to become important in the following months or years. Those APIs include handling payments, connecting to sensors, detecting shapes in images, loading fonts, connecting via Bluetooth and many more.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 6,
		"title": "Formen erkennen: die Shape Detection API",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-6-2017-1223853.html",
		"image": "web-mobile-developer_2017_06.jpg",
		"excerpt": "Die Erkennung von Gesichtern, Barcodes und Texten spielt in modernen Webanwendungen und mobilen Apps eine immer wichtigere Rolle: in Facebook beispielsweise ist es möglich, innerhalb von geposteten Bildern die Gesichter von Freunden zu markieren, die mobile Anwendung für das soziale Musiknetzwerk Discogs (discogs.com) erlaubt das Einscannen der Barcodes von Schallplatten und CDs, um so relativ unkompliziert seine Musiksammlung digital zu erfassen und über die Android- App Text Fairy lassen sich eingescannte Texte automatisch in Textdokumente umwandeln (Stichwort OCR, „Optical Character Recognition“). Nur drei von vielen Beispielen für die Erkennung bestimmter Formen innerhalb von Bilddaten. Über die neue Shape Detection API, die sich gerade in der Entwicklung befindet, lassen sich innerhalb von Bilddaten bestimmte Formen wie etwa Gesichter, Barcodes, QR-Codes oder Text erkennen.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 5,
		"title": "Struktur der Daten: Dynamische APIs mit GraphQL",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-5-2017-1217359.html",
		"image": "web-mobile-developer_2017_05.jpg",
		"excerpt": "Mit GraphQL hat Facebook eine Anfragesprache geschaffen, über die sich auf Clientseite die Struktur der Daten formulieren lässt, die von einem Webservice zurückgegeben werden sollen. Dieser Artikel gibt eine kurze Übersicht über GraphQL und zeigt vor allem, inwieweit sich GraphQL von REST unterscheidet.",
		"tags": ["JS"]
	},
	{
		"issue": "net magazine",
		"year": 2017,
		"month": 5,
		"title": "15 Web APIs you've never heard of",
		"url": "http://www.creativebloq.com/net-magazine",
		"image": "net-magazine_2017_05.jpg",
		"excerpt": "There is really a huge number of Web APIs available and sometimes as a web developer it can be hard to keep track of new APIs. In this article I've collected a list of Web APIs that are not finalized yet and in very early stages, but are likely to become important in the following months or years. Those APIs include handling payments, connecting to sensors, detecting shapes in images, loading fonts, connecting via Bluetooth and many more.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 4,
		"title": "Formate und Tools: Dokumentation von REST-APIs",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-4-2017-1207283.html",
		"image": "web-mobile-developer_2017_04.jpg",
		"excerpt": "Eine gute und durchdachte REST API beginnt mit einer guten und durchdachten Dokumentation. Bevor es an die Implementierung geht, ist es daher empfehlenswert, sich genau zu überlegen, wie die zu entwickelnde API aufgebaut sein soll. Dadurch lassen sich konzeptionelle Schwachstellen oder Designfehler in einer API frühzeitig erkennen und beheben. Mittlerweile gibt es diesbezüglich einige interessante Formate und Tools, die das Planen, Entwerfen, Dokumentieren und sogar das Testen von REST APIs erleichtern bzw. unterstützen können. Die bekanntesten davon seien im Folgenden kurz vorgestellt: die RESTful API Modeling Language (kurz RAML), API Blueprint, OpenAPI (auch bekannt als Swagger) sowie APIDOC.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 4,
		"title": "Blick in den API-Dschungel, Teil 17: die Server-Sent Events API",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-4-2017-1207283.html",
		"image": "web-mobile-developer_2017_04.jpg",
		"excerpt": "Die Server-Sent Events API ermöglicht es, vom Server an den Client Nachrichten zu versenden. Perfekte Grundlage für die Darstellung von Echtzeitdaten.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "Creative Bloq",
		"year": 2017,
		"month": 3,
		"title": "20 Node.js modules you need to know",
		"url": "http://www.creativebloq.com/features/20-nodejs-modules-you-need-to-know",
		"excerpt": "Over recent years Node.js has become more and more popular, and it is now often used for developing the server side of web applications, or in general during the development process. At the time of writing, the homepage of npm – the package manager for Node.js – lists over a quarter of a million modules. I've put together a list of the ones I find useful in my daily work as a web and software developer, from image manipulation, string validation and PDF generation to minification, logging and the creation of command line applications.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "Entwickler Magazin Spezial",
		"year": 2017,
		"month": 3,
		"title": "Wir müssen reden - Bluetooth Low Energy in JavaScript und Node.js",
		"url": "https://entwickler.de/entwickler-magazin/entwickler-magazin-spezial-vol-11-trends-579786187.html",
		"image": "entwickler-magazin_2017_03.jpg",
		"excerpt": "Bluetooth bzw. Bluetooth Low Energy (BLE) wird im Bereich Internet of Things (IoT) bzw. Web of Things (WoT) immer interessanter, sei es, um vom Smartphone den Fitness- Tracker auszulesen oder um von einem Raspberry Pi auf die Messwerte von Temperatur- und anderen Sensoren zuzugreifen. Über die Node.js-Module bleno und noble lässt sich die Kommunikation zwischen BLE-Geräten sogar unter JavaScript nutzen.",
		"tags": ["JS", "IoT", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 3,
		"title": "Qualität steigern: Continuous Integration für Node.js-Projekte",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-3-2017-1192796.html",
		"image": "web-mobile-developer_2017_03.jpg",
		"excerpt": "Continuous Integration kann bei Software-Projekten dabei helfen, die Qualität der Software zu steigern: werden Änderungen in das jeweilige Versionskontrollsystem (Git, Subversion, etc.) committed, führt das CI-System (Continuous Integration System) automatisch („kontinuierlich“) bestimmte Prozesse aus, wie beispielsweise das Kompilieren von Quelltext, Unit- oder Integrationstests, Tests bezüglich der Code-Qualität, Ermittlung der Testabdeckung oder ähnliches.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 3,
		"title": "Blick in den API-Dschungel, Teil 16: die WebSocket API",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-3-2017-1192796.html",
		"image": "web-mobile-developer_2017_03.jpg",
		"excerpt": "Mittlerweile gibt es verschiedene Standardtechnologien, bei denen der Server Daten an den Client senden kann und die damit eine echte bidirektionale Kommunikation ermöglichen. Eine von diesen Technologien ist die sogenannte WebSocket API, um die es im Folgenden gehen soll.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 2,
		"title": "Mobile Webanwendungen: Einführung in Ionic 2",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-2-2017-1188188.html",
		"excerpt": "Im Gegensatz zu Ionic 1, welches auf Angular 1 basiert, basiert Ionic 2 – wenig verwunderlich - auf Angular 2. Für den Einstieg in die neue Version von Ionic ist es daher empfehlenswert, sich zunächst mit der neuen Architektur von Angular 2 zu beschäftigten, welches im Unterschied zu Angular 1 einen komponentenbasierten Ansatz hat und zudem den Fokus sehr stark auf die Entwicklung mit TypeScript legt (auch wenn weiterhin natürlich mit reinem JavaScript entwickelt werden kann).",
		"image": "web-mobile-developer_2017_02.jpg",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 2,
		"title": "Blick in den API-Dschungel, Teil 15: die Clipboard API",
		"url": "http://www.webundmobile.de/update/phone/highlights-ausgabe-2-2017-1188188.html",
		"image": "web-mobile-developer_2017_02.jpg",
		"excerpt": "Das programmatische Kopieren und Einfügen von Inhalten (Copy & Paste) innerhalb einer Webseite mit Hilfe von JavaScript war lange Zeit nur über Umwege möglich. Eine Bibliothek, die in den vergangenen Jahren häufig verwendet wurde (und auch noch heute verwendet wird) ist ZeroClipboard. Mit der Clipboard API versucht man beim W3C nun, einen standardisierten Ansatz auf den Weg zu bringen, der auf reinem JavaScript basiert und ohne zusätzlichen Browser-Plugins auskommt.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "net magazine",
		"year": 2017,
		"month": 2,
		"title": "20 Node.js modules you need to know",
		"url": "http://www.creativebloq.com/net-magazine",
		"image": "net-magazine_2017_02.jpg",
		"excerpt": "Over recent years Node.js has become more and more popular, and it is now often used for developing the server side of web applications, or in general during the development process. At the time of writing, the homepage of npm – the package manager for Node.js – lists over a quarter of a million modules. I've put together a list of the ones I find useful in my daily work as a web and software developer, from image manipulation, string validation and PDF generation to minification, logging and the creation of command line applications.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "heise Developer",
		"year": 2017,
		"month": 2,
		"title": "Desktopanwendungen mit JavaScript",
		"url": "https://heise.de/-3609943",
		"image": "heise-developer-logo.png",
		"excerpt": "JavaScript gilt als eierlegende Wollmilchsau unter den Programmiersprachen. Mittlerweile macht sie auch im Bereich der Desktopanwendungen eine gute Figur. Dieser Artikel stellt die beiden Frameworks NW.js und Electron vor, die die WebKit-Engine mit der Node.js-Runtime kombinieren.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "JAXenter",
		"year": 2017,
		"month": 1,
		"title": "Wir müssen reden - Bluetooth Low Energy in JavaScript und Node.js",
		"url": "https://jaxenter.de/bluetooth-le-51182",
		"excerpt": "Bluetooth bzw. Bluetooth Low Energy (BLE) wird im Bereich Internet of Things (IoT) bzw. Web of Things (WoT) immer interessanter, sei es, um vom Smartphone den Fitness- Tracker auszulesen oder um von einem Raspberry Pi auf die Messwerte von Temperatur- und anderen Sensoren zuzugreifen. Über die Node.js-Module bleno und noble lässt sich die Kommunikation zwischen BLE-Geräten sogar unter JavaScript nutzen.",
		"tags": ["JS", "IoT", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 1,
		"title": "Arbeitserleichterung: Datenvisualisierung mit D3.js",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-ausgabe-1-2017-1175540.html",
		"excerpt": "Die JavaScript-Bibliothek D3.js ist ein mächtiges und flexibles Werkzeug, wenn es darum geht, verschiedenste Arten von Diagrammen bzw. Datenvisualisierungen zu erzeugen. Allerdings haben die Mächtigkeit und die Flexibilität auch einen Preis: der Einstieg in D3.js ist nicht gerade einfach. Oder besser gesagt: möchte man komplexere Diagramme erstellen, muss man einiges an Einarbeitungszeit investieren, um die API von D3.js sicher zu beherrschen. Einfache Visualisierungen mögen vielleicht noch relativ schnell gelingen, komplexere wie beispielsweise Echtzeitdiagramme (Real Time Charts) sind da jedoch schon ein wenig aufwändiger zu implementieren.",
		"image": "web-mobile-developer_2017_01.jpg",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2017,
		"month": 1,
		"title": "Blick in den API-Dschungel, Teil 14: die Service Worker API",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-ausgabe-1-2017-1175540.html",
		"image": "web-mobile-developer_2017_01.jpg",
		"excerpt": "Im Gegensatz zu \"normalen\" Scripts, die sofort gestoppt werden, wenn die dazugehörige Webseite bzw. das entsprechende Browsertab bzw. Browserfenster geschlossen wird, handelt es sich bei Service Workern um Scripts, die unabhängig von einer Webseite im Hintergrund laufen. Dadurch ergeben sich wie in diesem Artikel gezeigt ganz neue Möglichkeiten, die insbesondere dann interessant sind, wenn eine Webanwendung (oder Webseite) offline- fähig sein soll.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 12,
		"title": "Komponenten entkoppeln: Messaging in JavaScript",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-ausgabe-12-2016-1146602.html",
		"excerpt": "Bei der Implementierung komplexer Software-Systeme, bei denen verschiedenen Komponenten bzw. Anwendungen miteinander interagieren müssen, können Messaging-Systeme dabei helfen, eine Kopplung zwischen den einzelnen Komponenten zu vermeiden. Statt dass diese direkt miteinander kommunizieren, findet die gesamte Kommunikation über das Messaging-System statt. Einzelne Komponenten schicken dazu Nachrichten an das Messaging-System oder rufen Nachrichten von dort ab. Messaging-Systeme übernehmen dabei verschiedene Aufgaben: zum einen können eingehende Nachrichten nach bestimmten Regeln verteilt werden, sodass diese von anderen Komponenten abgerufen werden können (Routing), zum anderen sorgen Messaging-Systeme dafür, Nachrichten zwischen verschiedenen Messaging- Protokollen zu übersetzen.",
		"image": "web-mobile-developer_2016_12.jpg",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 12,
		"title": "Blick in den API-Dschungel, Teil 13: die Selection API",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-ausgabe-12-2016-1146602.html",
		"image": "web-mobile-developer_2016_12.jpg",
		"excerpt": "Über die Selection API ist es möglich, Textinhalte auf einer Webseite per JavaScript auszuwählen und zu bearbeiten. Die API befindet sich momentan beim W3C im Status \"Working Draft\", wird mittlerweile aber von so gut wie allen modernen Browsern unterstützt.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "Java Magazin",
		"year": 2016,
		"month": 12,
		"title": "Wir müssen reden - Bluetooth Low Energy in JavaScript und Node.js",
		"url": "https://jaxenter.de/ausgaben/java-magazin-12-16",
		"image": "java-magazin_2016_12.jpg",
		"excerpt": "Bluetooth bzw. Bluetooth Low Energy (BLE) wird im Bereich Internet of Things (IoT) bzw. Web of Things (WoT) immer interessanter, sei es, um vom Smartphone den Fitness- Tracker auszulesen oder um von einem Raspberry Pi auf die Messwerte von Temperatur- und anderen Sensoren zuzugreifen. Über die Node.js-Module bleno und noble lässt sich die Kommunikation zwischen BLE-Geräten sogar unter JavaScript nutzen.",
		"tags": ["JS", "IoT", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 11,
		"title": "Programmierparadigmen Teil 4: Aspektorientierte Programmierung in JavaScript",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-aktuellen-ausgabe-1139838.html",
		"image": "web-mobile-developer_2016_11.jpg",
		"excerpt": "Bei der aspektorientierten Programmierung (kurz AOP) handelt es sich um ein Programmierparadigma, welches im Zusammenhang mit der objektorientierten Programmierung zum Einsatz kommt und deren Idee es ist, generische Funktionalitäten wie etwa Logging, Caching, Transaktionsmanagement etc. (sprich sogenannte Cross Cutting Concerns oder im Jargon der aspektorientierten Programmierung auch „Aspekte“) vom eigentlichen Anwendungscode getrennt zu halten und den Code dadurch insgesamt klarer und modularer zu halten.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 11,
		"title": "Blick in den API-Dschungel, Teil 12: die Internationalization API",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-aktuellen-ausgabe-1139838.html",
		"image": "web-mobile-developer_2016_11.jpg",
		"excerpt": "Sobald eine Webseite oder Webanwendung mehrsprachig sein soll, ist eine gescheite Internationalisierung ebensolcher unumgänglich. Die Idee dabei ist es - soviel dürfte bekannt sein - eine Anwendung so zu strukturieren, dass sie ohne großen Aufwand an verschiedene Sprachen (oder noch allgemeiner: an verschiedene Regionen) angepasst werden kann. Die ECMAScript 2017 Internationalization API ermöglicht die sprachabhängige Formatierung von Zahlenwerten, Datums- und Zeitangaben sowie den sprachabhängigen Vergleich von Zeichenketten.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 10,
		"title": "Programmierparadigmen Teil 3: Funktional reaktive Programmierung in JavaScript",
		"url": "http://www.webundmobile.de/beyond-dev/internet/highlights-aktuellen-ausgabe-1120943.html",
		"excerpt": "Der Begriff der funktional reaktiven Programmierung (kurz FRP) dürfte den meisten Web-Entwicklern schon begegnet sein. Doch was verbirgt sich eigentlich hinter diesem Programmierparadigma? Dieser Artikel soll eine Einführung geben und anhand einiger Beispiele und der JavaScript-Bibliothek RxJS die Prinzipien erläutern.",
		"image": "web-mobile-developer_2016_10.jpg",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 10,
		"title": "Blick in den API-Dschungel, Teil 11: die Command Line API",
		"url": "http://www.webundmobile.de/beyond-dev/internet/highlights-aktuellen-ausgabe-1120943.html",
		"image": "web-mobile-developer_2016_10.jpg",
		"excerpt": "Browsertools wie die Chrome Developer Tools, Firebug oder die Entwicklertools von Safari gehören zu den Standardwerkzeugen eines jeden Webentwicklers. Sei es, um den DOM-Baum einer Webseite zu inspizieren, Ausgaben auf der Konsole zu erzeugen, Ausdrücke und Funktionsaufrufe auszuwerten oder um Breakpoints für das Debuggen zu definieren, den JavaScript-Code einer Webanwendung Schritt für Schritt durchzugehen, die Anwendung zu profilen und den Netzwerkverkehr zu beobachten. Dass diese Tools verschiedene Funktionalitäten auch per JavaScript zur Verfügung stellen, wissen allerdings nur die wenigsten Entwickler. Gute Gelegenheit also, im Folgenden einmal einen kurzen Überblick über die relativ unbekannte Command Line API zu geben, die beispielsweise von Chrome, Safari und Firefox unterstützt wird.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "net magazine",
		"year": 2016,
		"month": 9,
		"title": "Generic Sensor API",
		"url": "http://www.creativebloq.com/news/20-top-new-tools-for-freelancers",
		"image": "net-magazine_2016_09.png",
		"excerpt": "There are many different web APIs that define interfaces for different kinds of sensors, such as the Geolocation API or the Device Orientation API. However, those APIs do not share a common interface. Currently in Working Draft, the new Generic Sensor API defines a common interface for accessing sensor information.",
		"tags": ["JS", "IoT", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 9,
		"title": "Programmierparadigmen Teil 2: Funktionale Programmierung in JavaScript",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-aktuellen-ausgabe-1115928.html",
		"excerpt": "Neben der objektorientierten Programmierung, die ich Ihnen in Ausgabe 07/2016 vorgestellt habe, ist die sogenannte funktionale Programmierung ein weiteres wichtiges Programmierparadigma, welches in JavaScript eine zentrale Rolle spielt. Doch was bedeutet funktionale Programmierung überhaupt? Welche Prinzipien liegen diesem Programmierparadigma zugrunde? Was ist der Unterschied zu der objektorientierten Programmierung? Und was der Unterschied zu der imperativen Programmierung?",
		"image": "web-mobile-developer_2016_09.jpg",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 9,
		"title": "Blick in den API-Dschungel, Teil 10: die History API",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-aktuellen-ausgabe-1115928.html",
		"image": "web-mobile-developer_2016_09.jpg",
		"excerpt": "Jedes Mal, wenn der Browser eine neue Webseite lädt oder auch, wenn innerhalb einer Webseite zu einer Sprungmarke (einem Anker) gesprungen wird, erzeugt er standardmäßig einen neuen Eintrag im Browserverlauf. Das History API ermöglicht den Zugriff auf diesen Browserverlauf per JavaScript.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 8,
		"title": "IoT-Programmierung mit Cylon.js",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-aktuellen-ausgabe-1111793.html",
		"image": "web-mobile-developer_2016_08.jpg",
		"excerpt": "Der Zugriff auf Microcontroller bzw. Sensoren und Aktoren wird auch im Bereich der JavaScript-Entwicklung immer interessanter: Plattformen wie Tessel (https://tessel.io/) und Espruino (http://www.espruino.com/) verfügen bereits standardmäßig über eigene JavaScript- Interpreter, sodass auf diesen Plattformen JavaScript- Anwendungen direkt ausgeführt werden können. Für den Zugriff auf Arduino-Geräte kommt dagegen in der Regel das sogenannte Firmata-Protokoll zum Einsatz (https://www.arduino.cc/en/Reference/Firmata), wobei es auch hierfür entsprechende JavaScript-Clients gibt. Kleiner Wehrmutstropfen: jeder Microcontroller verwendet sein eigenes SDK und definiert entsprechend sein eigenes API.",
		"tags": ["JS", "IoT"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 8,
		"title": "Blick in den API-Dschungel, Teil 9: die File API",
		"url": "http://www.webundmobile.de/update/entwickler/highlights-aktuellen-ausgabe-1111793.html",
		"image": "web-mobile-developer_2016_08.jpg",
		"excerpt": "Über das File API ist es möglich, per JavaScript auf lokale Dateien des Nutzers zuzugreifen, sofern dieser die jeweiligen Dateien zuvor in einem entsprechenden Dialog ausgewählt hat.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 7,
		"title": "Programmierparadigmen Teil 1: Objektorientierte Programmierung in JavaScript",
		"url": "http://www.webundmobile.de/update/entwickler/inhalt-aktuellen-web-mobile-developer-7-2016-1107559.html",
		"image": "web-mobile-developer_2016_07.jpg",
		"excerpt": "In JavaScript sieht Objektorientierung anders aus als in Sprachen wie Java. Dieser Artikel erläutert das Prinzip und geht dabei auf die prototypische, die pseudoklassiche und die auf Klassensyntax basierende Objektorientierung ein.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 7,
		"title": "Blick in den API-Dschungel, Teil 8: die Web Storage API",
		"url": "http://www.webundmobile.de/update/entwickler/inhalt-aktuellen-web-mobile-developer-7-2016-1107559.html",
		"image": "web-mobile-developer_2016_07.jpg",
		"excerpt": "Über das Web Storage API ist es Webentwicklern möglich, innerhalb einer Webanwendung Daten lokal auf Clientseite zu speichern. Dabei unterscheidet man prinzipiell zwischen zwei verschiedenen Arten von Browserspeichern: dem lokalen Browserpeicher, der übergreifen über verschiedene Browserfenster und –tabs zugänglich ist, sowie dem Sessionspeicher, der nur innerhalb eines einzelnen Browsertabs zugänglich ist.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 6,
		"title": "Process Management unter Node.js mit PM2",
		"url": "http://www.webundmobile.de/update/entwickler/inhalt-web-mobile-developer-06-2016-1104168.html",
		"image": "web-mobile-developer_2016_06.jpg",
		"excerpt": "In Produktionsumgebungen können Process Manager das Steuern einzelner Prozesse bzw. Anwendungen erleichtern. Node.js bringt von Haus aus zwar keinen Process Manager mit, das Modul PM2 schließt jedoch diese Lücke.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 6,
		"title": "Blick in den API-Dschungel, Teil 7: die Geolocation API und die Google Maps API",
		"url": "http://www.webundmobile.de/update/entwickler/inhalt-web-mobile-developer-06-2016-1104168.html",
		"image": "web-mobile-developer_2016_06.jpg",
		"excerpt": "Mit Hilfe des Geolocation APIs lassen sich Standortinformationen des Nutzers ermitteln. Diese Informationen können anschließend beispielsweise dazu verwendet werden, um über das Google Maps API den Standort des Nutzers auf einer Karte anzuzeigen.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "heise Developer",
		"year": 2016,
		"month": 5,
		"title": "Aus der Werkzeugkiste, Teil 1: Philip Ackermann",
		"url": "http://heise.de/-3215454",
		"image": "heise-developer-logo.png",
		"excerpt": "In einer neuen Interview-Reihe gewähren Entwickler heise Developer einen Einblick in ihre Toolsammlung. Den Anfang macht Softwareentwickler Philip Ackermann.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 5,
		"title": "Modularisierung in JavaScript",
		"url": "http://www.webundmobile.de/update/entwickler/inhalt-aktuellen-ausgabe-5-2016-1094358.html",
		"image": "web-mobile-developer_2016_05.jpg",
		"excerpt": "Gerade in großen Projekten ist es ratsam, den Quelltext in gewisser Art zu strukturieren und in einzelne Module zusammenzufassen. Einige Sprache wie Python unterstützen Module bereits nativ. In JavaScript dagegen fehlte ein solcher Support bis vor kurzem. Erst mit Version ES6 (bzw. ES2015) sind Module ein Bestandteil des Standards und damit der Sprache. Da dies aber relativ lange eben nicht der Fall war, haben sich im Laufe der Zeit verschiedene Entwurfsmuster und Techniken herausgebildet, um Quelltext modular aufzubauen und in wiederverwendbare Module zusammenzufassen.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 5,
		"title": "Blick in den API-Dschungel, Teil 6: die Constraint Validation API",
		"url": "http://www.webundmobile.de/update/entwickler/inhalt-aktuellen-ausgabe-5-2016-1094358.html",
		"image": "web-mobile-developer_2016_04.jpg",
		"excerpt": "Noch vor einigen Jahren war die Validierung von Formularfeldern nur mit JavaScript möglich. Mittlerweile bietet HTML schon von Haus aus gewisse Möglichkeiten zur Überprüfung von Formularfeldern an, was mittlerweile auch von allen gängigen Browsern unterstützt wird.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 4,
		"title": "Blick in den API-Dschungel, Teil 5: die IndexedDB API",
		"url": "http://www.webundmobile.de/update/web/inhalt-aktuellen-ausgabe-4-2016-1082995.html",
		"image": "web-mobile-developer_2016_04.jpg",
		"excerpt": "Das IndexedDB API bietet Web- Entwicklern die Möglichkeit, eine clientseitige – im Browser verfügbare – Datenbank zu nutzen. Vom Prinzip her funktioniert diese genauso wie auch eine serverseitige Datenbank, das heißt, man kann in ihr Datensätze erstellen, Datensätze auslesen, aktualisieren oder wieder löschen.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "iX",
		"year": 2016,
		"month": 3,
		"title": "Richtig schreiben - ES6-Klassen und Konfigurationsobjekte",
		"url": "https://shop.heise.de/katalog/ix-03-2016",
		"image": "ix_2016_03.jpg",
		"excerpt": "Konfigurationsobjekte helfen dabei, wenn es um die Erstellung von Objektinstanzen auf Basis von ES6-Klassen geht. Doch für sauberen Code ist dabei einiges zu beachten.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 3,
		"title": "Formulargenerierung mit JavaScript",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-3-2016-1077231.html",
		"image": "web-mobile-developer_2016_03.jpg",
		"excerpt": "Das manuelle Erstellen von Webformularen kann mitunter recht langweilig sein. Oftmals sind Formulare ähnlich aufgebaut (beispielsweise Bestellformulare, Login- Formulare oder Formulare zur Registrierung), sodass man über kurz oder lang wahrscheinlich einen Online- Formulargenerator zu Hilfe zieht oder zumindest aus einem Fundus von eigenen Formularvorlagen wählt und diese den jeweiligen Bedürfnissen anpasst. Bei AlpacaJS handelt es sich um ein Plugin für jQuery, mit Hilfe dessen sich automatisch Formulare per JavaScript generieren lassen. Die Konfiguration erfolgt über JavaScript-Objekte oder über JSON-Dateien.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 3,
		"title": "Blick in den API-Dschungel, Teil 4: die Navigation Timing API",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-3-2016-1077231.html",
		"image": "web-mobile-developer_2016_03.jpg",
		"excerpt": "Das Navigation Timing API bietet Web- Entwicklern die Möglichkeit, per JavaScript verschiedene Aspekte bezüglich der Performance einer Webseite zu messen. Die Spezifikation ist schon relativ lange eine Empfehlung des W3C und wird daher auch von dem Großteil der verschiedenen Browser unterstützt. Dennoch ist der Bekanntheitsgrad des APIs unter Webentwicklern eher gering. Gute Gelegenheit also, einmal einen etwas genaueren Blick darauf zu werfen.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 2,
		"title": "Visuelle Regressionstests unter Node.js mit WebDriverIO und Selenium 2.0",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-2-2016-1070820.html",
		"image": "web-mobile-developer_2016_02.jpg",
		"excerpt": "Regressionstests dienen in der Software-Entwicklung dazu, sicherzustellen, dass Änderungen an einer Software für bestehende Komponenten keine neuen Fehler verursachen. Dieser Artikel zeigt, wie Sie mit Hilfe der Bibliothek WebDriverIO Regressionstests für Webanwendungen durchführen können.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 2,
		"title": "Blick in den API-Dschungel, Teil 3: die Web Animations API",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-2-2016-1070820.html",
		"image": "web-mobile-developer_2016_02.jpg",
		"excerpt": "Das Web Animation API bietet Web-Entwicklern die Möglichkeit, innerhalb einer Webseite per JavaScript Animationen zu definieren und zu steuern.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 1,
		"title": "Git kompakt: Versionsverwaltung mit Git",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-1062941.html",
		"image": "web-mobile-developer_2016_01.jpg",
		"excerpt": "Das Versionsverwaltungssystem Git unterscheidet sich in vielerlei Hinsicht von anderen Versionsverwaltungssystemen. Dieser Artikel gibt einen kompakten Überblick über die Unterschiede und stellt die wichtigsten Konzepte und typischen Arbeitsabläufe von Git vor.",
		"tags": ["Git"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2016,
		"month": 1,
		"title": "Blick in den API-Dschungel, Teil 2: die Battery Status API",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-1062941.html",
		"image": "web-mobile-developer_2016_01.jpg",
		"excerpt": "In dieser Artikelreihe geht es monatlich um verschiedene Themen rund um die Webentwicklung. Nachdem vorigen Monat die Web Speech API vorgestellt wurde, geht es dieses Mal um die Battery Status API.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "iX Developer",
		"year": 2016,
		"month": 1,
		"title": "Topfit - Internet of Things für Einsteiger",
		"url": "https://shop.heise.de/katalog/ix-developer-internet-der-dinge-2015",
		"image": "ix-developer_2016_01.jpg",
		"excerpt": "Die Entwicklung prototypischer Anwendungen ist nur eines der Szenarien im IoT-Kontext, in denen sich JavaScript mittlerweile einsetzen lässt. Wer die Lingua Franca des Web beherrscht, ist häufig in der Lage, Brücken zwischen alter und neuer Technik zu schlagen.",
		"tags": ["JS", "IoT"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2015,
		"month": 12,
		"title": "Blick in den API-Dschungel, Teil 1: die Web Speech API",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-1055177.html",
		"image": "web-mobile-developer_2015_12.jpg",
		"excerpt": "Die Web Speech API bietet Web-Entwicklern die Möglichkeit, innerhalb einer Webseite programmatisch Sprache auszugeben und Sprache zu erkennen. Analog dazu definiert die API zwei Interfaces: SpeechRegognition für die Spracherkennung und SpeechSynthesis für die Sprachausgabe.",
		"tags": ["JS", "Web APIs"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2015,
		"month": 12,
		"title": "Schön geordnet: Gestaltungsraster mit dem CSS Grid Layout",
		"url": "http://www.webundmobile.de/update/internet/inhalt-aktuellen-ausgabe-1055177.html",
		"excerpt": "Die Verwendung von Gestaltungsrastern (englisch Grids) gehört mittlerweile zu den Best Practices bei der Entwicklung von Webseiten. Dabei werden die Elemente einer Webseite horizontal und vertikal anhand eines Rasters angeordnet, was insbesondere bei der Umsetzung von Layoutvorgaben eine enorme Erleichterung darstellen kann.",
		"image": "web-mobile-developer_2015_12.jpg",
		"tags": ["CSS"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 10,
		"title": "JavaScript und das Internet of Things, Teil 2: BeagleBone Black und Arduino",
		"url": "http://heise.de/-2838375",
		"image": "heise-developer-logo.png",
		"excerpt": "Neben Espruino und Tessel gibt es noch weitere Plattformen, die JavaScript-Entwicklern den Einstieg in die hardwarenahe Programmierung erleichtern.",
		"tags": ["JS", "IoT"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2015,
		"month": 10,
		"title": "Architekturmuster und Konzepte moderner JavaScript-Webframeworks",
		"url": "http://www.webundmobile.de/Aktuelles/News/Highlights-der-web-mobile-developer-10-2015-11607.html",
		"image": "web-mobile-developer_2015_10.jpg",
		"excerpt": "JavaScript-Webframeworks gibt es mittlerweile recht viele: AngularJS, EmberJS, KnockoutJS, BackboneJS und React um nur einige der prominentesten Vertreter dieser Gattung zu nennen. Die Konzepte und Architekturmuster, die in solchen Frameworks zum Einsatz kommen, sind jedoch oftmals recht ähnlich. Kenntnisse über diese Konzepte und Architekturmuster sollten also im Wissensschatz eines Web-Entwicklers nicht fehlen. Dieser Artikel gibt einen Überblick.",
		"tags": ["JS", "Angular", "React"]
	},
	{
		"issue": "heise Developer",
		"year": 2015,
		"month": 9,
		"title": "JavaScript und das Internet of Things, Teil 1: Espruino und Tessel",
		"url": "http://heise.de/-2806613",
		"image": "heise-developer-logo.png",
		"excerpt": "JavaScript, die Lingua franca des Web, kommt mittlerweile auch im Bereich des Internet der Dinge zum Einsatz, insbesondere bei der Entwicklung prototypischer Anwendungen.",
		"tags": ["JS", "IoT"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2015,
		"month": 9,
		"title": "Vorschau auf AngularJS 2.0",
		"url": "http://www.webundmobile.de/mobile/entwickler/highlights-web-mobile-developer-9-2015-1027590.html",
		"image": "web-mobile-developer_2015_09.jpg",
		"excerpt": "Kaum ein anderes JavaScript-Webframework hat einen ähnlichen Bekanntheits- und Beliebtheitsgrad wie AngularJS. Um so größer war zunächst die Empörung darüber, als bekannt wurde, dass sich für die neue Version des Frameworks sowohl Architektur als auch API komplett ändern würden. Nachdem nun der erste Schreck verflogen ist, muss man aber eingestehen: die Berücksichtigung und starke Integration von Standards wie Web Components oder ECMAScript6 sind langfristig die richtige Wahl. Dieser Artikel wirft einen ersten Blick auf die neue Version von AngularJS 2.0 und stellt dessen wichtigsten Konzepte kurz vor.",
		"tags": ["JS", "Angular"]
	},
	{
		"issue": "SCREENGUIDE",
		"year": 2015,
		"month": 7,
		"title": "Entwurfsmuster in JavaScript",
		"url": "http://www.screengui.de/",
		"excerpt": "Allgemein bezeichnen Entwurfsmuster in der Softwareentwicklung bestimmte Herangehensweisen für wiederkehrende Problemstellungen. In der objektorientierten Programmierung zählen die Entwurfsmuster der Gang of Four (GoF-Entwurfsmuster) zu den bekanntesten, die erstmals in dem bekannten Buch \"Entwurfsmuster – Elemente wiederverwendbarer objektorientierter Software\" beschrieben wurden. Dieser Artikel zeigt, wie einige dieser Entwurfsmuster in JavaScript umgesetzt werden und gibt eine Übersicht über die Relevanz der Entwurfsmuster in JavaScript.",
		"tags": ["JS"]
	},
	{
		"issue": "web & mobile Developer",
		"year": 2015,
		"month": 6,
		"title": "Testen von JavaScript-Anwendungen",
		"url": "http://www.webundmobile.de/mobile/datenbank/highlights-web-mobile-developer-6-2015-1027177.html",
		"image": "web-mobile-developer_2015_06.jpg",
		"excerpt": "Der Aspekt des automatisierten Testens ist unabhängig von der eingesetzten Programmiersprache ein wichtiger Bestandteil in der professionellen Software-Entwicklung. Auch bei der Entwicklung von JavaScript-Anwendungen lassen sich über entsprechende Bibliotheken Aspekte wie Unit-Testing, Mocking und Testabdeckung in den Entwicklungsprozess integrieren. Dieser Artikel stellt einige dieser Bibliotheken vor.",
		"tags": ["JS"]
	},
	{
		"issue": "SCREENGUIDE",
		"year": 2015,
		"month": 1,
		"title": "Neues in ECMAScript 6",
		"url": "http://www.screengui.de/",
		"excerpt": "Die nächste Version von ECMAScript (ES6), dem Standard hinter JavaScript bringt eine ganze Reihe von neuen Features. Neben Erweiterungen der Standard-API sind dies hauptsächlich syntaktische Feinheiten, die vieles erleichtern, was in ES5 nur über Umwege möglich war.",
		"tags": ["JS"]
	},
	{
		"issue": "iX",
		"year": 2015,
		"month": 1,
		"title": "Kompetente Puzzleteile - Wiederverwendbares HTML durch Web Components",
		"url": "https://shop.heise.de/katalog/ix-01-2015",
		"image": "ix_2015_01.jpg",
		"excerpt": "Cascading Stylesheets und JavaScript-Bibliotheken können Webdesigner in ihre HTML-Dokumente einbinden. Für HTML-Komponenten selbst gibt es lediglich das <iframe>-Element. Das soll sich durch die Implementierung mehrerer Spezifikationen in Browsern ändern.",
		"tags": ["JS", "HTML"]
	},
	{
		"issue": "iX",
		"year": 2014,
		"month": 2,
		"title": " Ausgemustert? GoF-Entwurfsmuster in JavaScript",
		"url": "https://shop.heise.de/katalog/ix-02-2014",
		"image": "ix_2014_02.jpg",
		"excerpt": "Die GoF-Entwurfsmuster stellen Lösungen für wiederkehrende Probleme dar, die in Programmiersprachen wie Java aufgrund fehlender Sprachmittel existieren. Doch ist ihre Verwendung in JavaScript gerechtfertigt?",
		"tags": ["JS"]
	},
	{
		"issue": "iX Developer",
		"year": 2014,
		"month": 1,
		"title": "Gut in Form - Übersicht: Entwurfsmuster in JavaScript",
		"url": "https://shop.heise.de/katalog/ix-developer-javascript-heute-2014",
		"image": "ix-developer_2014_01.jpg",
		"excerpt": "Seit JavaScript als Programmiersprache akzeptiert ist, mit der sich auch komplexere Anwendungen inklusive serverseitiger Komponenten entwickeln lassen, ist es für Programmierer umso wichtiger, den Quelltext den neuen Anforderungen entsprechend zu strukturieren. Mehrere Entwurfsmuster haben sich dafür etabliert.",
		"tags": ["JS"]
	},
	{
		"issue": "iX Developer",
		"year": 2014,
		"month": 1,
		"title": "Headless Browser Testing - Webseiten rendern und testen ohne Browser",
		"url": "https://shop.heise.de/katalog/ix-developer-javascript-heute-2014",
		"image": "ix-developer_2014_01.jpg",
		"excerpt": "Nachdem man den Code einer Anwendung geändert hat, sollte man prüfen, ob die Benutzeroberfläche noch wie gewünscht funktioniert. Mit PhantomJS lassen sich Webseiten rendern, ohne eine Browser-GUI zu starten und die Kombination mit CasperJS hilft dabei, Szenarien für Integrations- und Regressionstests zu erarbeiten.",
		"tags": ["JS", "Node.js"]
	},
	{
		"issue": "iX Developer",
		"year": 2014,
		"month": 1,
		"title": "Hilfestellung - Projektbericht: Adaptation von Webinhalten mit Node.js",
		"url": "https://shop.heise.de/katalog/ix-developer-javascript-heute-2014",
		"image": "ix-developer_2014_01.jpg",
		"excerpt": "Den Inhalt einer Webseite abhängig vom Nutzer, von den Eigenschaften des Endergäts und der verwendeten assistiven Software anzupassen, ist eine große Herausforderung. Der folgende Projektbericht zeigt, wie Node.js auf dem Client dazu eingesetzt wurde, nutzer- und gerätespezifische Informationen zu extrahieren und den Anwendern eine Möglichkeit zur Konfiguration ihrer Einstellungen zu geben.",
		"tags": ["JS", "Node.js"]
	}
];

const ArticlesTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { html: pageBody } = data.markdownRemark;
  const { title: pageTitle, description: pageDescription } = data.markdownRemark.frontmatter;
  const metaDescription = pageDescription !== null ? pageDescription : siteSubtitle;

  return (
    <Layout title={`${pageTitle} - ${siteTitle}`} description={metaDescription}>
      <Sidebar />
      <Page title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
		{
			articles.map((article) => (
				<div className="flex-container">
					<div class="flex-item">
						<h3>
							<a href={article.url} target="_blank">{article.title}</a>
						</h3>
						<small><strong>{article.issue}</strong> {article.month}/{article.year}</small>
						<div>{article.excerpt}</div>
					</div>
					{/* {article.image && <div class="flex-item">
						<img width="150px" src={`/media/magazines/${article.image}`} />
					</div>} */}
					{/* {
						article.tags.map((tag) => (
							<span class="badge">
								{tag}
							</span>
						))
					} */}
				</div>
			))
		}
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query ArticlesBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`;

export default ArticlesTemplate;
